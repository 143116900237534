.custom-toast-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    width: 90%;
    max-width: 400px;
}

.custom-toast {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-toast.warning .toast-body {
    background-color: #EBAA6A;
    color: #271C11;
    padding: 12px 16px;
    border-radius: 8px;
}

.custom-toast .toast-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-content {
    display: flex;
    align-items: center;
}

.toast-icon {
    margin-right: 8px;
}

.toast-close-btn {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    padding: 0;
    background: none;
    border: none;
}

.toast-close-btn:hover {
    color: #555;
}

.Toastify__close-button {
  color: #242B1B !important;
  opacity: 0.7 !important;
  font-size: 18px !important;
  padding-left: 16px !important; /* Add some space between text and close button */
}

.Toastify__close-button:hover {
  opacity: 1 !important;
}

.Toastify__toast-container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .Toastify__toast-container {
    padding: 16px !important; /* Equal padding on all sides */
  }
  
  .custom-toast {
    margin: 0 !important;
    width: 100% !important; /* Full width within the container */
  }
}
