.catalog-card {
    position: relative;
    border-radius: 12px;
    margin-bottom: 1em;
    padding: 16px;
    background-color: #FFFFFF;
    transition: box-shadow 0.3s ease;
    height: 120px;
    display: flex;
    flex-direction: column;
}

  
  .catalog-card-title {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1px;
  }
  
  .catalog-card-price {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 400;
  }
  
  .catalog-card-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #C5E1A5; 
    margin-bottom: 16px;
  }


  .catalog-card-status.mensual {
    background-color: #C5E1A5; 
    color: #24472E;
  }
  
  .catalog-card-status.semanal {
    background-color: #A9B8EA; /* Yellow */
    color: #161B2B;
  }
  
  .catalog-card-status.diario {
    background-color: #FF5722; /* Orange */
  }
  
  .catalog-card-status.evento {
    background-color: #B2E7F2;
    color: #192B2E;  /* Green */
  }
  
  .catalog-card-description {
    font-size: 14px;
    color: #1F1F1F;
    margin-bottom: 8px;
  }
  
  .catalog-card-edit {
    position: absolute;
    top: 16px;
    right: 16px;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 14px;
    cursor: pointer;
    color: #1F1F1F;
  }
  
  .catalog-card-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .catalog-card-tag {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 2px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: #333;
  }

  .catalog-card-tag.mensual {
    background-color: #D4E9B8;
    color: #24472E;
  }

  .catalog-card-tag.semanal {
    background-color: #A9B8EA;
    color: #161B2B;
  }

  .empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background-color: #F4EDE4;
    border-radius: 8px;
    margin-top: 2rem;
  }
  
  .empty-state-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
    background-color: #E3DDD4;
    border-radius: 50%;
    padding: 1rem;
  }
  
  .empty-state-container h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #1F1F1F;
  }
  
  .empty-state-container p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .add-product-button {
    background-color: #1F1F1F;
    color: #F4EDE4;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-product-button:hover {
    background-color: #333;
  }

@media (min-width: 992px) {
    .col-sm-4 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

/* Add these new styles */
.segmented-control-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.segmented-control {
  width: 100%;
}

@media (min-width: 992px) {
  .segmented-control-container {
    justify-content: flex-start;
    padding-left: 0;
  }
  
  .segmented-control {
    width: auto;
    min-width: 360px;
    max-width: 360px;
  }
}