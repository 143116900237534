.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: justified;
    padding: 20px;
    background-color: #f4ede4;
    border-radius: 10px;
  }
  
  .onboarding-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .onboarding-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .input-group label {
    font-weight: 400;
  }
  .input-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 12px !important;  /* Use !important to enforce this style */
    font-size: 16px;
    width: 100%;
    appearance: none;
}

.type-select{
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 12px !important;  /* Use !important to enforce this style */
    font-size: 16px;
    width: 100%;
    appearance: none;
    background-color: #FFFFFF;
}

  
  .save-button {
    background-color: #1F1F1F !important;
    border-radius: 50px !important;
    width: 100% !important;
    height: 48px !important;
    color: #FFFFFF !important;
    font-weight: 500 !important;
    border: none !important;
    margin-top: 20px !important;
    cursor: pointer !important;
    font-size: 16px;
  }
  
  
  .submit-btn:hover {
    background-color: #333;
  }
  

  .skip-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #666;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .skip-button:hover {
    background-color: #f0f0f0;
    color: #333;
  }

.availability-section {
  margin-top: 20px;
}

.availability-days {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.availability-days p {
  margin-bottom: 10px;
}

.day-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}

.day-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #C9C9C9;
  background-color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.day-button.active {
  background-color: #333;
  color: white;
  font-weight: 500;
}

.availability-hours {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.availability-hours div {
  flex: 1;
}

.availability-hours label {
  font-weight: 400;
  margin-bottom: 8px;
  display: block;
}

.availability-hours input[type="time"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 12px !important;
  font-size: 16px;
  width: 100%;
  appearance: none;
  background-color: #FFFFFF;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}

.save-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.save-button {
  width: 100%;
  max-width: 300px;
}