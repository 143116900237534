.edit-payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-payment-modal-content {
  background-color: #F4EDE4;
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
}

.edit-payment-modal-section {
  margin-bottom: 20px;
}

.edit-payment-modal-section label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.edit-payment-modal-section select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
}

.edit-payment-modal-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.edit-payment-modal-buttons button {
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
}

.edit-payment-cancel-button {
  background-color: white;
  border: 1px solid #000;
  color: #000;
}

.edit-payment-save-button {
  background-color: #000;
  border: none;
  color: white;
} 