.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #F4EDE4;
  z-index: 1000;
}
  
  .section-title {
    font-size: 24px;
    font-weight: 600;
    color: #1F1F1F;
  }
  
  .button-group {
    display: flex;
    align-items: center;
  }

  .button-add{
    margin-right: 0px;;
  }
  
  .button-add, .button-filter, .dropdown-toggle {
    font-size: 12px;
    background-color: #F4EDE4;
    color: #1F1F1F;  /* Ensures text color is black in normal state */
    border-radius: 50px;
    font-weight: 500;
    border: 1.5px solid #1F1F1F;
    padding: 8px;  /* Uniform padding */
    margin-right: 8px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-add{
    margin-right: 0px;;
  }
  

  .button-add:hover, .button-filter:hover, .dropdown-toggle:hover {
    background-color: #1F1F1F;
    color: #F4EDE4;  /* Ensures text color is white on hover */
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: inherit; /* This ensures that the color is inherited from the button-filter class */
  }
  
  .dropdown-toggle:focus, .dropdown-toggle:active {
    box-shadow: none;
    outline: none;
  }
  
  .dropdown-menu {
    border-radius: 20px;
  }

  .filter-container {
    position: relative;
  }
  

  .button-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    height: 36px;
    border-radius: 50px;
    background-color: #F4EDE4;
    color: #1F1F1F;
    border: 1.5px solid #1F1F1F;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
  }

  .button-filter:hover,
  .button-filter.active-filter {
    background-color: #1F1F1F;
    color: #F4EDE4;
  }

  .button-filter:hover .filter-icon,
  .button-filter.active-filter .filter-icon {
    filter: invert(1);
  }

  .filter-icon {
    width: 16px;
    height: 16px;
  }

  .filter-text {
    margin-left: 4px;
  }

  .filter-multi {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    min-width: 180px;
    display: none;
  }

  .filter-multi.show {
    display: block;
  }
  
  .filter-multi-select__control {
    border-radius: 20px !important; /* Adjust the value to get the desired roundness */
    background-color: #F4EDE4 !important; /* Change this to your desired background color */
    border: 1.5px solid #1F1F1F !important;
    font-size: 12px !important;
    color: #1F1F1F !important; /* Remove the border if you don't want it */
  }
  
  .filter-multi-select__value-container {
    padding: 2px 8px !important;
  }
  
  .filter-multi-select__indicator-separator {
    display: none !important; /* Remove the separator between the text and the dropdown arrow */
  }
  
  .filter-multi-select__dropdown-indicator {
    color: #1f1f1f !important; /* Change the color of the dropdown arrow */
  }
  
  /* Style for the options dropdown */
  .filter-multi-select__menu {
    border-radius: 5px !important;
    overflow: hidden;
  }
  
 

  /* Mobile-specific styles */
@media (max-width: 767px) {
  /* Hide the text label on small devices within the button */

  .button-filter {
    width: auto;
    padding: 8px 16px;
  }

  .filter-container {
    flex-wrap: wrap;
  }

  .filter-multi {
    width: 100%;
    margin-top: 8px;
  }
}



