
  
  /* Center the success container within its parent */
  .x-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Full viewport height */
    background-color: #F4EDE4; /* Background color to match the container */
  }
  

.x-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F4EDE4; /* Background color of your choice */
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    margin: auto;
    text-align: center;
  }
  
  .x-icon-container {
    background-color: #EA9280; /* Light green background for the icon */
    border-radius: 50%;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .x-icon {
    font-size: 24px;
    color: #000; /* Black color for the check mark */
  }
  
  .x-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .x-message {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .x-primary-button {
    background-color: #000;
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .x-secondary-button {
    background-color: #FFF;
    color: #000;
    border: 2px solid #000;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;
  }
  