.segmented-control {
  display: flex;
  gap: 32px;
  margin-bottom: 20px;
}

.segment-button {
  padding: 8px 0;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.segment-button:hover {
  color: #1F1F1F;
}

.segment-button.active {
  color: #1F1F1F;
  font-weight: 600;
}

.segment-button.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1F1F1F;
} 