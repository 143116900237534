.personal-info-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  align-items: left;
  margin-bottom: 20px;
  justify-content: flex-start; /* Align to the left */
}

.back-button {
  background: none;
  border: none;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  margin-right: 15px; /* Add some space between button and title */
}

.personal-info-title {
  font-size: 24px;
  margin: 0; /* Remove default margin */
}

.personal-info-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.readonly-input {
  background-color: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.5; /* Add opacity to denote it cannot be edited */
}

.save-button-container {
  margin-top: 20px;
}

.save-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}
