/* =================
   Global Styles 
   ================= */
body, html {
  font-family: 'Inter', sans-serif;
}

/* =================
   Layout Container 
   ================= */
.schedule-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  background-color: #FFFFFF;
  color: #1f1f1f;
  font-family: 'Inter', sans-serif;
}

/* =================
   Week Date Picker Component
   Used in WeekDatePicker() 
   ================= */
.week-date-picker {
  background-color: #F4EDE4;
  padding: 10px;
  margin-bottom: 20px;
}

.month-header-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.month-header {
  margin: 0;
  font-size: 24px;
  text-transform: capitalize;
}

.days-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.day-item.selected {
  background-color: #1f1f1f;
  color: #F4EDE4;
}

.day-item.today:not(.selected) {
  border: 1px solid #1f1f1f;
}

/* Week Navigation Buttons */
.week-nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #1f1f1f;
  padding: 0 5px;
}

.week-nav-button svg {
  width: 24px;
  height: 24px;
  stroke-width: 2.5;
}

/* Week Navigation Buttons - Desktop */
.desktop-week-nav {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-left: auto;
}

.week-range {
  font-size: 0.9rem;
  color: #666;
  min-width: 150px;
  text-align: center;
}

.week-nav-button-desktop {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
  transition: all 0.2s ease;
}

.week-nav-button-desktop:hover {
  opacity: 0.7;
}

.week-nav-button-desktop svg {
  width: 20px;
  height: 20px;
  stroke-width: 2;
}

/* =================
   FullCalendar Core Styles
   Used in <FullCalendar> component 
   ================= */
.custom-calendar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Calendar Header */
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  padding: 10px;
  background-color: #F4EDE4;
  border-radius: 5px;
}

.fc .fc-toolbar-title {
  font-size: 14px;
  font-weight: 700;
  color: #1f1f1f;
  text-transform: uppercase;
}

/* Calendar Grid */
.fc .fc-timegrid-slot {
  height: 3em;
}

.fc .fc-timegrid-axis {
  font-size: 0.8em;
}

.fc .fc-timegrid-slot-label {
  font-size: 0.9em;
}

/* Today's Column Styling */
.fc .fc-day-today {
  background-color: rgba(229, 243, 255, 0.4) !important;
}

/* =================
   Event Styles
   Used in renderEventContent() 
   ================= */
/* Base event styles */
.fc-event {
  margin: 0 0 5px 0 !important;
  border-radius: 12px !important;
  overflow: visible !important;
  padding: 10px 0 0 10px !important;
}

/* Regular appointment (confirmed) */
.fc-event:not(.out-of-office-event):not(.pending-confirmation) {
  background-color: #6E8352 !important;
  border: 1px solid #6E8352 !important;
  padding: 10px !important;
  margin-bottom: 5px !important;
}

.fc-event:not(.out-of-office-event):not(.pending-confirmation) .fc-event-main {
  color: #FFFFFF !important;
}

/* Out of office events */
.out-of-office-event {
  background-color: #D4E9B8 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 10px 0 0 10px !important;
  border-radius: 12px !important;
  margin-bottom: 5px !important;
  height: calc(100% - 4px) !important;
}

.out-of-office-event .event-content {
  padding: 10px !important;
  height: 100%;
}

.out-of-office-event .event-title,
.out-of-office-event .event-time {
  color: #1f1f1f !important;
  padding-left: 10px !important;
}

/* Pending confirmation events */
.pending-confirmation {
  background-color: white !important;
  border: 1.5px dashed #495737 !important;
  padding: 10px 0 0 10px !important;
  margin: 0 !important;
  height: 100% !important;
}

.pending-confirmation .event-content {
  padding: 0 !important;
  height: 100%;
}

.pending-confirmation .event-title,
.pending-confirmation .event-time,
.pending-confirmation .event-details {
  color: #495737 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.fc-timegrid-event {
  margin: 0 !important;
  padding: 0 !important;
}

.fc-timegrid-event-harness {
  margin: 0 !important;
}

/* Event content styling */
.event-content {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
}

.event-title {
  font-size: 0.95rem !important;
  line-height: 1.2 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin: 0 !important;
}

.event-time {
  font-size: 0.85rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin: 0 !important;
}

.event-details {
  font-size: 0.85rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style all text content within pending confirmation events */
.pending-confirmation .fc-event-main {
  color: #495737 !important;
}

/* Style specific elements within pending confirmation events */
.pending-confirmation .event-title,
.pending-confirmation .event-time,
.pending-confirmation .event-details {
  color: #495737 !important;
}

/* Update or add these styles */
.fc-timegrid-event-harness {
  /* Remove any fixed height/width constraints if they exist */
  height: auto !important;
}

.pending-harness {
  /* Ensure the harness respects the event duration */
  position: absolute;
  height: 100% !important;
  width: 100% !important;
}

/* Ensure these styles apply to all event types */
.fc-event .event-content,
.out-of-office-event .event-content,
.pending-confirmation .event-content {
  max-width: 100%;
  box-sizing: border-box;
}

/* =================
   View Toggle Buttons
   Used in WeekDatePicker() view toggle 
   ================= */
.view-toggle {
  display: flex;
  align-items: center;
}

.view-toggle .btn-group {
  margin-left: 10px;
}

.view-toggle .btn {
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 15px;
  border: 1px solid #1f1f1f;
  background-color: white;
  color: #1f1f1f;
  transition: all 0.3s ease;
}

.view-toggle .btn-primary {
  background-color: #1f1f1f;
  color: white;
}

/* =================
   Responsive Styles 
   ================= */
@media (max-width: 768px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
  
  .fc .fc-toolbar-title {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  .week-date-picker .month-header {
    font-size: 28px;
  }

  .week-date-picker .day-item {
    width: 35px;
    height: 35px;
  }

  .week-date-picker .day-name,
  .week-date-picker .day-number {
    font-size: 13px;
  }
}

.today-button {
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* Remove blue color and underline from day headers */
.fc .fc-col-header-cell a {
  color: #000000;
  text-decoration: none;
}

/* Remove hover effects if any */
.fc .fc-col-header-cell a:hover {
  color: #000000;
  text-decoration: none;
}

/* Style for the day name */
.fc .fc-col-header-cell-cushion {
  text-transform: lowercase; /* If you want lowercase day names */
  font-weight: normal;
}

