@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  background-color: #F4EDE4;
  font-family: 'Inter', sans-serif;
  margin-bottom: 80px;
 

}


body.modal-open {
  padding-right: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  width: 100% !important;
}

.contain-register-login {
  max-width: 500px;
}

.title-login-register {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.label-login-register-form {
  color: #3C3934;
  font-size: 14px;
}

.loginbtn {
  background-color: #1F1F1F;
  width: 100%;
  border-radius: 60px;
  height: 60px;
  border: transparent;
}

/* Button when loading */
.loginbtn.loading {
  background-color: #1F1F1F; /* Button color when loading */
  border-color: #1F1F1F; /* Ensure the border color matches */
}

.loginbtn:hover {
  background-color: #1F1F1F;
  width: 100%;
  border-radius: 60px;
  height: 60px;
  border: transparent;
}

.login-form-input {
  border-radius: 12px;
  border: 1px solid #CAC6C1;
  height: 48px;
  margin-bottom: 20px;
}

.alt-register-login {
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  color: #1F1F1F;
}

.alt-register-login a {
  color: #1F1F1F;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.inputgroup-register-login {
  margin-top: 16px;
}

.privacy-tos {
  color: #444444;
  font-size: 12px;
}


.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #F4EDE4;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  body {
      padding-left: 0;
      padding-bottom: 60px;
  }
}

.main-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}


.version-text {
  margin: 0; /* Remove any default margins */
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  /* The background color and color will be set inline by React */
}

.version-profile-container {
  display: flex;
  align-items: center;
  gap: 10px; /* This creates space between the label and the icon */
}

.profile-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* The background color will be set inline by React */
}

.profile-icon svg {
  width: 24px;
  height: 24px;
  fill: #FFFFFF;
}

/* If there's a specific hover effect, add it here */
.profile-icon:hover {
  color: #007bff;  /* Example hover color */
}

/* Add this new class */
.full-width-content {
  padding-left: 0 !important;
  max-width: 100% !important;
}
.container {
  padding: 10px;
}

@media (min-width: 769px) {
  body:not(.full-width-content) {
    padding-left: 150px; /* Match the navbar width */
  }
  
  .container {
    width: calc(100% - 250px); /* Subtract navbar width from container */
    margin-left: 150px; /* Match navbar width */
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 80px; /* Add space for mobile navbar */
  }
}