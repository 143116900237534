.payment-details-container {
    padding: 16px;
    padding-bottom: 80px;
    background-color: #F4EDE4;
    min-height: 100vh;
  }
  
  .payment-details-header {
    margin-bottom: 24px;
  }
  
  .back-button {
    background: none;
    border: none;
    padding: 0;
    color: #000;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .payment-amount {
    font-size: 32px;
    font-weight: bold;
  }
  
  .payment-details-content {
    background: #F4EDE4;
    border-radius: 8px;
    padding: 16px;
  }
  
  .detail-section {
    margin-bottom: 24px;
  }
  
  .detail-section:last-child {
    margin-bottom: 0;
  }
  
  .detail-section h2 {
    font-size: 12px;
    color: #666;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .detail-section p {
    font-size: 16px;
    color: #000;
    margin: 0;
  }
  
  .status-tag {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
  }
  
  .status-tag.pagado {
    background-color: #E8F5E9;
    color: #2E7D32;
  }
  
  .invoice-button {
    background-color: #FFF8E1;
    color: #F9A825;
    border: none;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
  }
  
  .invoice-tag {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    background-color: #FFF8E1;
    color: #F9A825;
  }
  
  .invoice-tag.facturado {
    background-color: #E8F5E9;
    color: #2E7D32;
  }
  
  .invoice-tag.por_facturar {
    background-color: #FFF8E1;
    color: #F9A825;
  }
  
  .action-buttons {
    padding: 16px;
    display: flex;
    gap: 16px;
    background-color: #F4EDE4;
    z-index: 100;
    margin-top: 24px;
  }
  
  .action-buttons button {
    flex: 1;
    padding: 12px;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: white;
    border: 1px solid #000;
    color: #000;
  }
  
  .edit-button {
    background-color: #000;
    border: none;
    color: white;
  }