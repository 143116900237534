.payment-settings {
    background-color: #F4EDE4;
    padding: 20px;
    font-family: 'Inter', sans-serif;
}

@media (min-width: 769px) {
    .payment-settings {
        margin-left: 200px; /* This should match the width of your navbar */
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.back-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.back-button::before {
    content: "←";
    margin-right: 5px;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

h2 {
    font-size: 11px;
    font-weight: 600;
    color: #4B4B4B;
    margin-bottom: 10px;
}

p {
    font-size: 14px;
    font-weight: 400;
    color: #4B4B4B;
    margin-bottom: 10px;
}


.payment-reminders {
    margin-top: 24px;
    border-bottom: 1px solid #ccc;

}   



.payment-method-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.payment-method-toggle span {
    font-size: 14px;
    color: #333;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .1s;
    border-radius: 28px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: .2s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #000;
}

input:checked + .slider:before {
    transform: translateX(19px);
}

.bank-transfer-config {
    margin-top: 24px;
    border-bottom: 1px solid #ccc;
}


.input-group {
    margin-bottom: 16px;
}

.input-group label {
    display: block;
    font-size: 14px;
    color: #333;
}

.input-group input {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
}

.input-group input:focus {
    outline: none;
    border-color: #999;
}



.stripe-config {
    margin-top: 24px;
    border-bottom: 1px solid #ccc;

}


.configure-stripe-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 16px;

}

.settings-section-title{
    margin-top: 24px;
    border-bottom: 1px solid #ccc;
}

.settings-section-title h2{
    font-size: 11px;
    font-weight: 600;
    color: #4B4B4B;
}

.payment-day, .available-days, .penalty-rate {
    margin-bottom: 15px;
}

.payment-day select, .number-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 16px;
}

.number-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.number-input button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
}

.number-input input {
    text-align: center;
    width: 50px;
    margin: 0 10px;
}

.penalty-rate input {
    width: 60px;
}

.penalty-rate span {
    margin-left: 5px;
}

.penalty-rate p {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}


.custom-number-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 30px;
    overflow: hidden;
  }
  
  .custom-number-input button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    font-size: 18px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .custom-number-input input {
    border: none;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    width: 50px;
    background-color: transparent;
  }
  
  .custom-number-input input:focus {
    outline: none;
  }

.save-button-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #F4EDE4;
    z-index: 1000;
}

@media (min-width: 769px) {
    .save-button-container {
        left: 200px; /* This should match the width of your navbar */
    }
}

.save-button {
    width: 100%;
    max-width: 300px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.save-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.save-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.save-button-disabled:hover {
    background-color: #007bff;
}

.settings-section-title{
    margin-top: 24px;
    border-bottom: 1px solid #ccc;
}

.settings-section-title h2{
    font-size: 14px;
    font-weight: 600;
    color: #4B4B4B;
}

.payment-day, .available-days, .penalty-rate {
    margin-bottom: 15px;
}

.payment-day select, .number-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 16px;
}

.number-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.number-input button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
}

.number-input input {
    text-align: center;
    width: 50px;
    margin: 0 10px;
}

.penalty-rate input {
    width: 60px;
}

.penalty-rate span {
    margin-left: 5px;
}

.penalty-rate p {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}


.custom-number-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 30px;
    overflow: hidden;
  }
  
  .custom-number-input button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    font-size: 18px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .custom-number-input input {
    border: none;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    width: 50px;
    background-color: transparent;
  }
  
  .custom-number-input input:focus {
    outline: none;
  }


  .logica-de-pago {
    background-color: #F4EDE4;
    padding: 20px;
    border-radius: 10px;
}

.logica-de-pago h2 {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}

.payment-logic-item {
    margin-bottom: 20px;
}

.payment-logic-item label {
    display: block;
    margin-bottom: 5px;
    color: #666;
}

.select-wrapper, .number-input-wrapper, .input-with-symbol {
    background-color: white;
    border-radius: 25px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.select-wrapper select, 
.number-input-wrapper input,
.input-with-symbol input {
    border: none;
    background: transparent;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select-wrapper::after {
    content: "▼";
    font-size: 12px;
    position: absolute;
    right: 15px;
    color: #666;
}

.number-input-wrapper button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0 10px;
    color: #666;
}

.plan-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plan-message p {
    margin: 0;
}

.upgrade-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.upgrade-button:hover {
    background-color: #0056b3;
}

input[type="checkbox"]:disabled,
input[type="number"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.save-button-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #F4EDE4;
    z-index: 1000;
}

.save-button {
    width: 100%;
    max-width: 300px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.save-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.save-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.save-button-disabled:hover {
    background-color: #007bff;
}

.settings-section-title{
    margin-top: 24px;
    border-bottom: 1px solid #ccc;
}

.settings-section-title h2{
    font-size: 11px;
    font-weight: 600;
    color: #4B4B4B;
}

.payment-day, .available-days, .penalty-rate {
    margin-bottom: 15px;
}

.payment-day select, .number-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 16px;
}

.number-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.number-input button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
}

.number-input input {
    text-align: center;
    width: 50px;
    margin: 0 10px;
}

.penalty-rate input {
    width: 60px;
}

.penalty-rate span {
    margin-left: 5px;
}

.penalty-rate p {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}


.custom-number-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 30px;
    overflow: hidden;
  }
  
  .custom-number-input button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    font-size: 18px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .custom-number-input input {
    border: none;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    width: 50px;
    background-color: transparent;
  }
  
  .custom-number-input input:focus {
    outline: none;
  }