.catalog-item-details-page {
  background-color: #F4EDE4;
  min-height: 100vh;
}

.catalog-item-details-container {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
}

.item-content {
  flex: 1;
  margin-bottom: 24px;
}

.item-details-header {
  margin-bottom: 32px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 24px;
}

.item-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}

.item-sections {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.item-section {
  padding: 16px 0;
  border-bottom: none;
}

.item-section h2 {
  font-size: 12px;
  color: #333;
  margin-bottom: 8px;
}

.section-content {
  font-size: 16px;
  font-weight: 500;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.catalog-tag {
  background-color: #E6E6E6;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.cadence-tag {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
  color: #fff;
}

.action-buttons {
  display: flex;
  gap: 12px;
  padding: 16px;
  background-color: #FAF7F2;
  margin-top: auto;
  margin-bottom: 24px;
}

.delete-button, .edit-button {
  flex: 1;
  border-radius: 100px;
  padding: 12px 24px;
}

.delete-button {
  border: 1px solid #333;
  background-color: transparent;
}

.edit-button {
  background-color: #333;
  border: none;
  color: white;
}

@media (min-width: 768px) {
  .catalog-item-details-container {
    max-width: 768px;
    margin: 0 auto;
  }

  .action-buttons {
    padding: 0;
  }
} 