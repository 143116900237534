.navbar-custom {
    background-color: #1F1F1F;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 15px;
    border-right: 1px solid #2D2D2D;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
}

.nav-item {
    margin-bottom: 8px;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    color: #FFFFFF;
    text-decoration: none;
    transition: background-color 0.2s;
    border-radius: 8px;
}

.nav-link:hover, .nav-link.active {
    background-color: #2D2D2D;
    border-radius: 8px;
    font-weight: 500;
}

.navbar-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    filter: invert(1);
}

.navbar-text {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .navbar-custom {
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 0;
        top: auto;
        flex-direction: row;
        justify-content: space-around;
        padding: 8px 0;
        background-color: #F4EDE4;
        border-top: 1px solid #E0E0E0;
    }

    .navbar-nav {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .nav-item {
        margin: 0;
        flex: 1;
        height: 100%;
        position: relative;
    }

    .nav-link {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        color: #1F1F1F;
        gap: 4px;
        background-color: transparent;
    }

    .nav-link.active {
        background-color: transparent;
        color: #1F1F1F;
    }

    .nav-link.active::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 40px;
        background-color: #D2CCC4;
        border-radius: 20px;
        z-index: 0;
    }

    .nav-link .navbar-icon,
    .nav-link .navbar-text {
        position: relative;
        z-index: 1;
    }

    .navbar-icon {
        width: 24px;
        height: 24px;
        margin: 0;
        filter: none;
        color: #626262;
    }

    .navbar-text {
        font-size: 12px;
        margin: 0;
        font-weight: 400;
        color: #1F1F1F;
    }

    .nav-link:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 40px;
        background-color: #D2CCC4;
        border-radius: 20px;
        z-index: 0;
    }

    .nav-link:hover {
        background-color: transparent;
        color: #1F1F1F;
    }

    .nav-link.active .navbar-icon {
        color: #626262;
    }
}

@media (min-width: 769px) {
    .navbar-custom {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .navbar-logo-desktop {
        display: block;
        padding: 20px 15px;
        margin-bottom: 20px;
    }

    .navbar-logo-desktop img {
        height: 25px;
        width: auto;
    }

    .navbar-profile-desktop {
        display: block;
        margin-top: auto;
        padding: 15px;
    }

    .navbar-nav {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .navbar-logo-desktop,
    .navbar-profile-desktop {
        display: none;
    }
}

@media (min-width: 769px) {
    .navbar-custom {
        padding: 15px 15px; /* Increase side padding for desktop */
    }

    .nav-link {
        padding: 6px 10px; /* Further reduce horizontal padding */
        margin: 0 5px; /* Increase side margins slightly for desktop */
    }

    .navbar-nav {
        padding-top: 10px; /* Add some top padding to the nav items container */
    }

    body {
        padding-left: 200px;
    }

    .navbar-nav {
        padding-top: 0;
    }

    .nav-link {
        padding: 6px 16px;
    }

    .navbar-icon {
        width: 16px;
        height: 16px;
    }

    .navbar-text {
        font-size: 16px; 
    }
}

@media (max-width: 768px) {
    body {
        padding-bottom: 84px;
    }
}