/* Base Modal Styles */
.base-modal {
  padding-right: 0 !important;
}

.base-modal .modal-dialog {
  margin: 0 !important;
  max-width: 100% !important;
  transition: transform 0.3s ease-in-out !important;
}

.base-modal .modal-content {
  border: none !important;
  height: 100% !important;
}

/* Mobile Styles (fullscreen) */
@media (max-width: 767px) {
  .base-modal {
    padding: 0 !important;
    margin: 0 !important;
  }

  .base-modal .modal-dialog {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    transform: translateY(100%) !important;
    max-width: 100vw !important;
  }

  .base-modal.show .modal-dialog {
    transform: translateY(0) !important;
  }

  .base-modal .modal-content {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    width: 100% !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .base-modal .modal-header {
    flex-shrink: 0 !important;
    padding: 24px 16px !important;
  }

  .base-modal .modal-body {
    flex: 1 1 auto !important;
    overflow-y: auto !important;
    padding: 0 16px !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .base-modal .modal-footer {
    flex-shrink: 0 !important;
    position: sticky !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: white !important;
    padding: 16px !important;
    padding-bottom: max(16px, env(safe-area-inset-bottom, 20px)) !important;
    margin-top: auto !important;
  
    z-index: 1050 !important;
  }

  .base-modal .form-group {
    margin-bottom: 24px !important;
  }

  .base-modal .form-group:last-child {
    margin-bottom: 80px !important;
  }
}

/* Desktop Styles (slide from right) */
@media (min-width: 768px) {
  .base-modal {
    padding: 0 !important;
    margin: 0 !important;
  }

  .base-modal .modal-dialog {
    position: fixed !important;
    right: 5px !important;
    top: 5px !important;
    bottom: 5px !important;
    width: 500px !important;
    height: calc(100vh - 10px) !important;
    transform: translateX(calc(100% + 5px)) !important;
    margin: 0 !important;
    max-width: 500px !important;
  }

  .base-modal.show .modal-dialog {
    transform: translateX(0) !important;
  }

  .base-modal .modal-content {
    border-radius: 5px !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
}

/* Modal Header */
.base-modal .modal-header {
  padding: 24px 16px !important;
  border-bottom: none;
}

.base-modal .modal-title {
  font-size: 24px;
  font-weight: 500;
}

/* Modal Body */
.base-modal .modal-body {
  padding: 0 16px !important;
  overflow-y: auto;
}

/* Modal Footer */
.base-modal .modal-footer {
  padding: 24px 16px !important;
  border-top: none;
}

/* Form Elements */
.base-modal .form-group {
  margin-bottom: 24px;
}

/* Primary Button Style */
.modal-primary-btn {
  width: 100%;
  height: 56px;
  background-color: #1F1F1F !important;
  border: none !important;
  border-radius: 100px !important;
  padding: 16px !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  transition: background-color 0.2s ease-in-out !important;
}

.modal-primary-btn:hover {
  background-color: #333333 !important;
}

.modal-primary-btn:active {
  background-color: #000000 !important;
}

.modal-primary-btn:disabled {
  background-color: #C9C9C9 !important;
  cursor: not-allowed;
}

/* Secondary Button Style */
.modal-secondary-btn {
  width: 100%;
  background-color: transparent !important;
  color: #1F1F1F !important;
  border: none !important;
  padding: 12px !important;
  margin-top: 8px !important;
  text-decoration: underline !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.modal-secondary-btn:hover {
  color: #333333 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.modal-secondary-btn:active {
  color: #000000 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

/* Form Elements */
.base-modal .form-group {
  margin-bottom: 24px;
}

.base-modal .form-label {
  color: #1F1F1F;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

/* Dropdowns (Form.Select) */
.base-modal .form-select {
  width: 100%;
  height: 56px;
  padding: 16px;
  border: 1px solid #CAC6C1;
  border-radius: 12px;
  background-color: #FFFFFF;
  color: #1F1F1F;
  font-size: 16px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%231F1F1F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 12px;
}

.base-modal .form-select:focus {
  border-color: #1F1F1F;
  box-shadow: none;
  outline: none;
}

/* Input fields (Form.Control) */
.base-modal .form-control {
  width: 100%;
  height: 56px;
  padding: 16px;
  border: 1px solid #CAC6C1;
  border-radius: 12px;
  background-color: #FFFFFF;
  color: #1F1F1F;
  font-size: 16px;
  appearance: none;
}

.base-modal .form-control:focus {
  border-color: #1F1F1F;
  box-shadow: none;
  outline: none;
}

/* Input fields */
.base-modal .form-control::placeholder {
  color: #8E8E8E;
}

/* Section Headers */
.base-modal .details-header {
  color: #1F1F1F;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 32px 0 16px 0;
}

/* Toggle Switches */
.base-modal .form-switch {
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
}

.base-modal .form-switch .form-check-input {
  float: none;
  margin-left: 0;
  width: 44px;
  height: 24px;
  background-image: none;
  border: none;
  background-color: #CAC6C1;
  cursor: pointer;
}

.base-modal .form-switch .form-check-input:checked {
  background-color: #1F1F1F;
}

.base-modal .form-switch .form-check-input:focus {
  box-shadow: none;
  border: none;
}

/* Custom toggle switch appearance */
.base-modal .form-switch .form-check-input:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transform: translateX(2px);
  transition: transform 0.2s ease-in-out;
}

.base-modal .form-switch .form-check-input:checked:before {
  transform: translateX(22px);
}

/* Cost Input Group */
.base-modal .cost-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.base-modal .cost-input-wrapper .currency-prefix {
  position: absolute;
  left: 16px;
  color: #1F1F1F;
}

.base-modal .cost-input-wrapper .currency-suffix {
  position: absolute;
  right: 16px;
  color: #1F1F1F;
}

.base-modal .cost-input-wrapper .form-control {
  padding-left: 32px;
  padding-right: 56px;
}

/* Divider */
.base-modal .divider {
  height: 1px;
  background-color: #CAC6C1;
  margin: 24px 0;
}

/* Error States */
.base-modal .form-control.is-invalid,
.base-modal .form-select.is-invalid {
  border-color: #dc3545;
  background-image: none;
}

.base-modal .invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
}

/* Disabled States */
.base-modal .form-control:disabled,
.base-modal .form-select:disabled {
  background-color: #F5F5F5;
  cursor: not-allowed;
}

/* Payment Modal Specific Styles */
.payment-modal .product-selector {
    position: relative;
}

.payment-modal .selected-classes-summary {
    padding: 16px 24px;
    background-color: #F5F5F5;
    border-top: 1px solid #CAC6C1;
}

.payment-modal .selected-class-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #CAC6C1;
}

.payment-modal .selected-class-item:last-child {
    border-bottom: none;
}

.payment-modal .total-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid #CAC6C1;
    font-weight: 600;
}

.payment-modal .class-select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #CAC6C1;
    border-radius: 12px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-modal .class-select-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.payment-modal .class-select-item:hover {
    background-color: #F5F5F5;
} 