.select-plan-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Inter, sans-serif;
    padding-bottom: 70px; 
}

h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #333;
}

.guarantee {
    text-align: left;
    color: #666;
    margin-bottom: 20px;
}

.plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.plan-card {
    background-color: white;
    border: 1px solid #1f1f1f;
    border-radius: 18px;
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
}

.plan-card.selected {
    border-color: #24472E;
    border-width: 2px;
    box-shadow: 0 0 10px rgba(0, 100, 0, 0.1);
}

.plan-card.popular {
    border-color: #4a90e2;
}

.popular-tag {
    position: absolute;
    top: -10px;
    right: 10px;
    background-color: #24472E;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.plan-card h3 {
    color: #333;
    margin-bottom: 10px;
    font-size: 18px;
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.price-details {
    color: #666;
    font-size: 14px;
    margin-bottom: 15px;
}

.features {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
}

.features ul {
    list-style-type: none;
    padding-left: 0;
}

.features li {
    margin-bottom: 5px;
}

.select-button {
    background-color: white;
    border: 2px solid #006400;
    color: #006400;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 12px;
}

.selected .select-button {
    background-color: #006400;
    color: white;
}

.confirm-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #1f1f1f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add any other styles you want for the button */
}

.confirm-button:disabled {
    background-color: none;
    cursor: not-allowed;
    color: #1f1f1f;
    border-radius: 50px;
}

.error-message {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.fixed-bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: none;
    padding: 10px;
    border-radius: 50px;
}
