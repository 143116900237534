.modal-body {
    background-color: #FFFFFF;
    padding: 12px;
    padding-top: 56px; /* Add padding to the top to account for the fixed header */
    overflow-y: auto; /* Make the modal body scrollable */
    max-height: calc(100vh - 56px - 56px); /* Adjust height to account for header and footer */
  }

.edit-event-form .form-group {
  margin-bottom: 14px; /* Increased from 0.5rem to 1.5rem */
}

.edit-event-form .form-label {
  margin-bottom: 1px   ; /* Added a small margin below the label */
  font-weight: bold;
}

.edit-event-form .form-control-plaintext {
  padding-top: 0;
  padding-bottom: 0.25rem; /* Added a small padding below the content */
}

.modal-footer {
  justify-content: flex-end;
}

.delete-button {
  color: #dc3545;
  padding: 0;
}

.delete-button:hover {
  color: #bd2130;
  text-decoration: underline;
}