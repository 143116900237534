.filter-modal .modal-dialog {
  margin: 1rem auto;
  max-width: 400px;
  width: calc(100% - 2rem);
}

.filter-modal .modal-content {
  border-radius: 20px;
  border: none;
  background-color: #F4EDE4;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.filter-modal .modal-header {
  border-bottom: none;
  padding: 24px 24px 16px;
  align-items: center;
}

.filter-modal .modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #1F1F1F;
  margin: 0;
  width: 100%;
  text-align: center;
}

.filter-modal .modal-header .btn-close {
  position: absolute;
  right: 24px;
  padding: 0;
  margin: 0;
  opacity: 1;
}

.filter-modal .modal-body {
  padding: 0 24px;
}

.filter-section {
  margin-bottom: 24px;
}

.filter-section h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #1F1F1F;
}

.sort-select .select__control,
.tag-select .select__control {
  border-radius: 12px !important;
  border: 1px solid rgba(31, 31, 31, 0.2) !important;
  background-color: white !important;
  min-height: 48px !important;
  box-shadow: none !important;
}

.sort-select .select__placeholder,
.tag-select .select__placeholder {
  color: rgba(31, 31, 31, 0.5) !important;
  font-size: 14px;
}

.sort-select .select__value-container,
.tag-select .select__value-container {
  padding: 2px 16px !important;
}

.helper-text {
  color: rgba(31, 31, 31, 0.5);
  font-size: 12px;
  margin-top: 8px;
}

.filter-modal .modal-footer {
  border-top: none;
  padding: 16px 24px 24px;
  margin-top: 16px;
}

.filter-apply-button {
  width: 100%;
  padding: 14px;
  background-color: #1F1F1F;
  color: #F4EDE4;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-apply-button:hover {
  opacity: 0.9;
}

/* Mobile specific styles */
@media (max-width: 767px) {
  .filter-modal .modal-dialog {
    margin: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    transform: translate(0, 0) !important;
    transition: transform 0.3s ease-out;
  }

  .filter-modal .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  /* Animation classes */
  .filter-modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }

  .filter-modal:not(.show) .modal-dialog {
    transform: translate(0, 100%) !important;
  }
} 