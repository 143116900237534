@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.main-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px; 
  background-color: #F4EDE4;
  font-family: 'Inter', sans-serif;
}

.main-bar .logo {
  height: 25px;
  margin-bottom: 10px;
}

.main-bar .beta-text {
  font-size: 14px;
  font-weight: bold;
  color: #1F1F1F;
}
 
.main-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: #F4EDE4;
 
  }
  
  .main-bar .logo {
    height: 25px;
    margin-bottom: 10px;
  }
  
  .main-bar .beta-text {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #1F1F1F;
    background-color: #d3b4fd;
    border-radius: 50px;
    width: 70px ;
  }
  
@media (min-width: 769px) {
    .main-bar {
        display: none;
    }
}
  