.card {
    position: relative;
    border-radius: 12px;
    margin-bottom: 1em;
    padding: 4px;
    background-color: #fff;
    border-color: transparent;

  }
  
  .card-title {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }
  
  .card a {
    color: #1F1F1F;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 16px;
  }
  
  .card-status {
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: #e57373; /* Adjust color as needed */
    color: #1F1F1F;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
  }

  .card-status.corriente {
    background-color: #B8DB8A; /* Green */
    color: #1F1F1F;
  }
  
  .card-status.pendiente {
    background-color: #EBAA6A; /* Yellow */
    color: #1F1F1F;
  }
  
  .card-status.atrasado {
    background-color: #EA9280; /* Orange */
    color: #1F1F1F;
  }
  
.card-status.nuevo{
  background-color: #8CA1E3; 
  color: #1F1F1F; 
}

  .card-body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 40px; /* Adjust this value based on your button height */
  }
  
  .card-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .card-classes {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .card-classes span {
    background-color: #C9C9C9;
    color: #1F1F1F;
    font-weight: 600;
    padding: 4px 6px;
    border-radius: 16px;
    font-size: 12px;
  }
  
  .card-edit {
    margin-left: auto;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 14px;
    cursor: pointer;
    color: #1F1F1F;
  }

  .card-send-sms {
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 14px;
    cursor: pointer;
    color: #1F1F1F;
    width: 100px;
}

.card-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px; /* This creates space between the buttons */
  justify-content: space-between;
  margin-top: auto; /* This pushes the actions to the bottom of the card */
}

.card-edit, .card-send-sms {
  padding: 8px 0;
  cursor: pointer;
  font-size: 0.9em;
  transition: color 0.3s ease;
  background: none; /* Remove background */
  border: none; /* Remove border if any */
  text-decoration: underline;
   /* Add underline to make it look clickable */
}

.card-edit {
  color: #1f1f1f;
  margin-right: 10px; /* Bootstrap primary color */
}

.card-send-sms {
  color: #1f1f1f; /* Bootstrap success color */
}

.card-edit:hover, .card-send-sms:hover {
  opacity: 0.7;
}


.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background-color: #F4EDE4;
  border-radius: 8px;
  margin-top: 2rem;
}

.empty-state-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  background-color: #E3DDD4;
  border-radius: 50%;
  padding: 1rem;
}

.empty-state-container h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #1F1F1F;
}

.empty-state-container p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.add-contact-button {
  background-color: #1F1F1F;
  color: #F4EDE4;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-contact-button:hover {
  background-color: #333;
}

.card-subscriptions {
    margin-top: 2px;
    font-size: 8px;
    color: #1f1f1f;

}

.card-subscriptions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
}

.subscription-pill {
    background-color: #C9C9C9;
    color: #1F1F1F;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 12px;
}

.no-subscriptions {
    color: #666;
    font-size: 12px;
}

.card-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.card-edit {
    background: none;
    border: none;
    color: #1F1F1F;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

.card-edit:hover, .card-send-sms:hover {
  opacity: 0.7;
}


.fab-container {
  position: fixed;
  bottom: 50px;
  right: 2rem;
  z-index: 1000;
}

.fab {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: background-color 0.3s ease;
}

.fab:hover {
  background-color: #0056b3;
}

@media (min-width: 992px) {
  .fab-container {
    position: absolute;
    right: 0;
    bottom: -70px;
  }
}
