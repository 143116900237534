.reports-container {
  padding: 10px;
}

.reports-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.reports-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.report-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.report-card h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.report-content {
  display: flex;
  flex-direction: column;
}

.total {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 5px;
}

.change {
  font-size: 14px;
  color: #666;
}

.balance-card-container {
  grid-column: 1 / -1;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.balance-card-container h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
}

.balance-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.balance-card-item {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.balance-card-item h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.balance-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.balance-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.balance-info {
  font-size: 0.8rem;
  color: #666;
  line-height: 1.4;
}
