/* Only keep specific styles for inventory modal */
.inventory-modal .number-input-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inventory-modal .number-input-wrapper .number-control {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #F5F5F5;
  border: none;
  color: #1F1F1F;
}

.inventory-modal .number-input-wrapper .number-input {
  width: 80px;
  text-align: center;
}

.required {
  color: red;
  margin-left: 4px;
}

.form-control.is-invalid,
.form-select.is-invalid {
  border-color: #dc3545;
  background-image: none;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.cost-input-wrapper .form-control.is-invalid {
  border-color: #dc3545;
}


