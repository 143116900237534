.event-details-page {
  background-color: #F4EDE4 !important;
}

.event-details-container {
  position: relative;
  min-height: calc(100vh - 64px);
  padding: 32px;
  padding-bottom: 100px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #F4EDE4;
}

.event-details-header {
  margin-bottom: 24px !important;
}

.back-button {
  padding: 0;
  color: #000;
  text-decoration: none !important;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
}

.back-button:hover {
  text-decoration: none !important;
  color: #000;
}

.event-title {
  font-size: 32px !important;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 1.2;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.event-datetime {
  margin-bottom: 24px;
  font-size: 16px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.video-call-buttons {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.video-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
}

.event-sections {
  margin: 32px auto;
  max-width: 600px;
}

.event-section {
  margin-bottom: 20px;
}

.event-section h2 {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  margin-bottom: 12px;
  letter-spacing: 0.5px;
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.status-tag {
  color: #1F1F1F;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
}

.event-actions {
  max-width: 600px;
  margin: 48px auto 0;
  display: flex;
  gap: 16px;
  justify-content: center;
  background: transparent;
  padding: 0;
}

.event-actions button {
  flex: 1;
  max-width: 280px;
  padding: 12px 24px;
  border-radius: 24px;
}

.delete-button {
  border: 1px solid #000 !important;
  background: transparent !important;
  color: #000 !important;
}

.edit-button {
  background: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
}

.event-recurrence {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  margin-top: 8px;
  font-size: 0.9em;
}

.recurrence-icon {
  font-size: 1.1em;
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .event-details-page {
    background-color: #fff;
    min-height: 100vh;
  }
  
  .event-details-page .mobile-nav,
  .event-details-page nav {
    display: none !important;
  }

  .event-details-container {
    padding: 16px;
  }

  .event-title, 
  .event-datetime,
  .event-sections,
  .event-actions {
    max-width: 100%;
  }
  
  .event-title {
    font-size: 24px !important;
  }
  
  .event-actions {
    padding: 0 16px;
  }
} 