.client-detail-page {
  padding: 20px;
  background-color: #F4EDE4;
  min-height: 100vh;
}

.client-detail-container {
  max-width: 800px;
  margin: 0 auto;
}

.back-button {
  padding: 0;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.client-header {
  margin-bottom: 30px;
}

.client-header h1 {
  margin: 0 0 32px 0;
  font-size: 32px;
  font-weight: 600;
  display: inline-block;
  padding-bottom: 4px;
}

.edit-button {
  width: 100%;
  background: #000;
  border: none;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
}

.info-section {
  margin-bottom: 32px;
}

.info-section label {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.info-section span {
  font-size: 16px;
  color: #1f1f1f;
}

.value-with-copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.copy-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.copy-button svg {
  width: 20px;
  height: 20px;
  stroke: #1f1f1f;
}

.tab-content-section {
  padding: 24px 0;
}

/* First, reset Bootstrap styles */
.nav-tabs,
.nav-tabs .nav-item,
.nav-tabs .nav-link {
  all: unset !important;
}

/* Custom tab styles */
.segment-tabs {
  display: flex !important;
  gap: 32px !important;
  margin: 24px 0 32px 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.segment-link {
  color: #8E8E8E !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
  border-radius: 0 !important;
  position: relative !important;
}

/* Active state - make sure this selector matches exactly */
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-link.active,
.segment-link.active {
  color: #000 !important;
  border-bottom: 2px solid #000 !important;
  padding-bottom: 8px !important;
}

/* Remove any hover effects */
.segment-link:hover,
.segment-link:focus,
.segment-link:active {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
}

@media (max-width: 768px) {
  .segment-tabs {
    gap: 24px !important;
  }
  
  .segment-link {
    font-size: 18px !important;
  }
}

.subscriptions-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.subscription-pill {
  background: #E6F2E6;
  color: #000;
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 16px;
}

.tags-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.client-tag {
  background: #E6E6E6;
  color: #000;
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 16px;
}

.no-subscriptions {
  color: #666;
  font-style: italic;
}

.payment-item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.status-pill {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.status-pill.pagado {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-pill.enviado {
  background-color: #fff3e0;
  color: #ef6c00;
}

.status-pill.reembolsado {
  background-color: #eceff1;
  color: #546e7a;
}

.status-pill.pago-vencido {
  background-color: #ffebee;
  color: #c62828;
}

.action-buttons {
  margin-top: 32px;
  padding: 16px;
  display: flex;
  gap: 16px;
  background: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.action-buttons button {
  flex: 1;
  padding: 16px;
  border-radius: 100px;
  font-size: 16px;
  cursor: pointer;
}

.action-buttons .delete-button {
  background: transparent;
  border: 1px solid #1f1f1f;
  color: #1f1f1f;
}

.action-buttons .edit-button {
  background: #1f1f1f;
  border: none;
  color: white;
}

/* Remove pill/button styles */
.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

/* Additional reset for any button-like styles */
button.nav-link,
button.nav-link:before,
button.nav-link:after {
  all: unset !important;
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
}

/* You might also want to ensure it stays on hover */
.segment-link:hover {
  cursor: pointer !important;
} 