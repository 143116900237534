/* Reset any inherited styles */
.payment-item * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container styles */
.payment-list {
  background-color: #F4EDE4;
  padding: 8px 0;
}

.payment-item {
  background-color: #F4EDE4;
  padding: 16px;
  border-bottom: 1px solid #C9C9C9 !important;
}

/* Header with name and amount */
.payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.client-name {
  font-size: 20px;
  font-weight: 600;
  color: #1f1f1f;
}

.payment-amount {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #1f1f1f;
}

/* Description and date */
.payment-description {
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 4px;
}

.payment-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
}

/* Footer with details link and status tags */
.payment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.details-link {
  background: none;
  border: none;
  padding: 0;
  color: #1f1f1f;
  font-size: 14px;
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}

.status-tags {
  display: flex;
  gap: 8px;
}

.status-tag {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
}

/* Status-specific styles */
.status-paid {
  background-color: #E2F0D0;
  color: #395942;
  border: 1px solid #395942 !important;
}

.status-sent {
  background-color: #F1E6C5;
  color: #2B2616 !important;
  border: 1px solid #2B2616 !important;
}

.status-pending {
  background-color: #EEEEEE;
  color: #666666;
}

.invoice-tag {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
}

.invoice-pending {  /* Por facturar */
  background-color: #F1E6C5;
  color: #2B2616 !important;
  border: 1px solid #2B2616 !important;
}

.invoice-completed {  /* Facturado */
  background-color: #E2F0D0 !important;
  color: #395942 !important;
  border: 1px solid #395942 !important;
}

.invoice-none {  /* Sin Factura */
  background-color: #E0E0E0;
  color: #666666;
  border: none;
}

.fab-container {
    position: fixed;
    bottom: 40px; /* Lower position for desktop */
    right: 16px;
    z-index: 1000; /* Ensure this is higher than your navbar's z-index */
}

.fab {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #1f1f1f; /* Changed to match your app's color scheme */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.fab:hover {
    background-color: #333; /* Darker shade for hover state */
    transform: scale(1.1);
}

.fab:focus {
    outline: none;
}

/* Mobile styles */
@media (max-width: 768px) {
    .fab-container {
        bottom: 120px; /* Higher position for mobile */
    }
}

/* Limit FAB position on larger screens */
@media (min-width: 1440px) {
    .fab-container {
        right: calc(50% - 760px + 16px); /* 720px is half of 1440px */
    }
}

#filter-dropdown {
  background-color: #F4EDE4;
  border: 1px solid #1f1f1f;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 12px;
  color: #1f1f1f;
  
}

#filter-dropdown:hover,
#filter-dropdown:focus {
  background-color: #1f1f1f;
  color: #F4EDE4;
}

#filter-dropdown::after {
  display: none;
}

#filter-dropdown i {
  margin-right: 8px;
}

.filter-container {
  position: relative;
  display: inline-block;
}

.filter-select {
  background-color: #F4EDE4;
  border: 1px solid #1f1f1f;
  border-radius: 20px;
  padding: 8px 30px 8px 16px;
  font-size: 12px;
  color: #1f1f1f;
  cursor: pointer;
  width: auto;
}


/* EMPTY STATE */
.no-payments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background-color: #F4EDE4;
  border-radius: 8px;
  margin-top: 2rem;
}

.no-payments-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  background-color: #E3DDD4;
  border-radius: 50px;
  padding: 20px;
}

.no-payments-container h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.no-payments-container p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.add-payment-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-payment-button:hover {
  background-color: #333;
}

.status-paid {
  background-color: #E2F0D0;
  color: #395942;
  border: 1px solid #395942 !important;
}

.status-sent {
  background-color: #F1E6C5;
  color: #2B2616 !important;
  border: 1px solid #2B2616 !important;
}

.status-pending {
  background-color: #EEEEEE;
  color: #666666;
}

.payment-status {
  display: flex;
  margin: 0;
}

.status-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-details-button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.view-details-button:hover {
  background: none;
  color: #333;
}
