.current-plan-short {
    line-height: auto;
    display: flex;
    justify-content: center;
    background: white;
    border-radius: 12px;
    border: 1px solid #8F8F8F;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    height: 48px;
 
}

.plan-tag{
    background-color: #F2BDB2;
    border-radius: 50px;
    width: 70px;
    text-align: center;
    height: 18px;
    margin-left: 5px;
}





/* BALANCE CARDS */ 
.balance-cards {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    margin-bottom: 36px;
}

.balance-card  {
    background: white;
    border-radius: 12px;
    padding: 8px;
    width: 49%;
    text-align: center;
    border: 1px solid #7B9081;
    border-width: 1px;
}

.balance-card-pending {
    background: white;
    border: 1px solid #EA9280;
    border-width: 1px;
    border-radius: 12px;
    padding: 8px;
    width: 49%;
    text-align: center;
}



.balance-card h3 {
    font-size: 11px;
    margin-bottom: 4px;
    color: #395942;
    border-radius: 50px;
    width: auto;
    padding-top: 1px;
    text-align: center;
    font-weight: 400;
}

.balance-card-pending h3{
    font-size: 11PX;
    margin-bottom: 4px;
    color: #8C574C;
    border-radius: 50px;
    width: auto;
    padding-top: 1px;
    text-align: center;
    font-weight: 400;
}

.balance-amount {
    font-size: 16px;
    font-weight: 700;
    margin-top: 4px;
    color: #1F1F1F;
    margin-bottom: 4px;
}




/*DATA*/ 

.profile-data-group{
    background-color: #E3DCD4;
    font-size: 14px;
    padding: 12px; 
    border-radius: 12px;
    margin-bottom: 12px;
    border-color: #1F1F1F;
    border: 1.5px solid #1F1F1F;
}


.profile-data-group h3{
    font-size: 14px; 
    font-weight: 500;
    margin-bottom: 12px;
    padding-top: 10px; 
}


.profile-group-header{
    display: flex;
    justify-content: space-between;
}


.profile-data-fields{
    display: flex;
}

.edit-profile-button{
    margin-left: auto;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 14px;
    cursor: pointer;
    color: #1F1F1F;
    background-color: transparent;
    border-color: transparent;
}

.edit-profile-button:hover{
    margin-left: auto;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-size: 14px;
    cursor: pointer;
    color: #1F1F1F;
    background-color: transparent;
    border-color: transparent;
}


/*MENU ITEM*/

.menu-item{
    color: #1F1F1F;
    height: 80px;
    border-bottom: 1px solid #C9C9C9;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;
}

.menu-item:hover {
    background-color: #f0f0f0;
}

.menu-item-text {
    flex-grow: 1;
}

.menu-item h3 {
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}

.menu-item p {
    color: #4B4B4B;
    font-size: 13px;
    margin: 0;
}

.menu-item-icon {
    width: 24px;
    height: 24px;
}

.edit-stripe-button {
    background-color: #B8DB8A;
    border-radius: 50px;
    width: 100%;
    height: 48px;
    color: #1F1F1F; 
    font-weight: 500; 
    border: none; 
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
}



/*LOGOUT*/

.logout-group{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
   justify-content: space-around;
}

.logoutbutton{
    color: white;
    font-weight: 400;
    background-color: #1F1F1F;
    border-radius: 50px;
    font-size: 16px;
    height: 56px;
    width: 180px;
}

.logoutbutton:hover{
    color: white;
    font-weight: 500;
    background-color: #1F1F1F;
    border-radius: 50px;
}


/*FOOTER*/


.extra-info{
    text-align: center;
    font-size: 12px;
    color: #626262; 
    margin-bottom: 150px;


}